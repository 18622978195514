import {
  Avatar,
  Button,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { Confetti } from "src/components/Confetti";
import { useDocumentStorage } from "src/hooks/useDocumentStorage";
import {
  useLazyRemoteDataQuery,
  useRemoteDataQuery,
} from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_MATCH_DETAILS, GET_STUDENTS_AVATARS } from "./graphql/queries";
import { useParams } from "react-router-dom";
import { ResultStatus } from "./Details";
import { ExitPublicViewButton } from "./components/ExitPublicViewButton";
import { JumpToResultsButton } from "./components/JumpToResultsButton";
import { Loading } from "src/components/Feedback/Loading";
import React from "react";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { getLogoAlt } from "src/schemas/Branding";
import { useBranding } from "src/components/Providers/BrandingProvider";

interface MatchResultRow {
  "App ID": string;
  "Student First Name": string;
  "Student Last Name": string;
  "Student ID": string;
  Grade: string;
  School: string;
  "School ID": string;
  "Lottery Result": ResultStatus;
  "Calculated Waitlist position": string;
}

export interface MatchResult {
  schoolId: string;
  schoolName: string;
  grade: string;
  placement: ResultStatus;
  students: {
    name: string;
    formId: string;
    waitlistPosition: string;
    avatar: string | null;
  }[];
}

export const PublicView: React.FC = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const { matchId } = useParams<{ matchId: string }>();
  const { getDownloadUrl } = useDocumentStorage();
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [matchResults, setMatchResults] = useState<MatchResult[]>([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const branding = useBranding();

  const { remoteData: matchData } = useRemoteDataQuery<
    GQL.GetMatchDetails,
    GQL.GetMatchDetailsVariables
  >(GET_MATCH_DETAILS, {
    variables: { matchId: matchId ?? "" },
    skip: !matchId,
  });

  const [getStudentsAvatars] = useLazyRemoteDataQuery<
    GQL.GetStudentsAvatars,
    GQL.GetStudentsAvatarsVariables
  >(GET_STUDENTS_AVATARS);

  // Fetch and parse CSV when match data is loaded
  useEffect(() => {
    async function fetchResults() {
      if (!matchData.hasData()) return;
      const documentId = matchData.data.match_run_by_pk?.results_document_id;
      if (!documentId) return;

      setIsLoadingResults(true);
      try {
        const documentUrl = await getDownloadUrl(documentId);
        if (!documentUrl) throw new Error("Failed to get document URL");

        const response = await fetch(documentUrl);
        const csvText = await response.text();

        Papa.parse<MatchResultRow>(csvText, {
          header: true,
          complete: async (results) => {
            const parsedResults = results.data
              .filter((row) => row["App ID"]) // Filter out empty rows
              .map((row) => ({
                formId: row["App ID"],
                grade: row["Grade"],
                student: {
                  name: `${row["Student First Name"]} ${row["Student Last Name"]}`,
                  id: row["Student ID"],
                },
                school: row["School"],
                schoolId: row["School ID"],
                placement: row["Lottery Result"],
                waitlistPosition: row["Calculated Waitlist position"],
              }));

            const studentIds = parsedResults.map((result) => result.student.id);
            const studentsAvatars = await getStudentsAvatars({
              variables: { studentIds },
            });

            const matchResults = parsedResults.reduce((acc, result) => {
              const schoolGradePlacement = acc.find(
                (s) =>
                  s.schoolId === result.schoolId &&
                  s.grade === result.grade &&
                  s.placement === result.placement
              );

              if (schoolGradePlacement) {
                schoolGradePlacement.students.push({
                  name: result.student.name,
                  formId: result.formId,
                  waitlistPosition: result.waitlistPosition,
                  avatar:
                    studentsAvatars.data?.person.find(
                      (p) => p.id === result.student.id
                    )?.avatar ?? null,
                });
              } else {
                acc.push({
                  schoolId: result.schoolId,
                  schoolName: result.school,
                  grade: result.grade,
                  placement: result.placement,
                  students: [
                    {
                      name: result.student.name,
                      formId: result.formId,
                      waitlistPosition: result.waitlistPosition,
                      avatar:
                        studentsAvatars.data?.person.find(
                          (p) => p.id === result.student.id
                        )?.avatar ?? null,
                    },
                  ],
                });
              }
              return acc;
            }, [] as MatchResult[]);

            setMatchResults(matchResults);
            setIsLoadingResults(false);
          },
          error: (error: any) => {
            console.error("CSV Parse Error:", error);
            setMatchResults([]);
          },
        });
      } catch (error) {
        console.error("Error fetching results:", error);
        setMatchResults([]);
        setIsLoadingResults(false);
      }
    }

    fetchResults();
  }, [matchData, getDownloadUrl, getStudentsAvatars]);

  // Add effect to trigger confetti when schoolIndex changes
  useEffect(() => {
    if (matchResults[pageIndex]?.placement === "Offer") {
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [pageIndex, matchResults]);

  return (
    <Flex
      width="100%"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={1000}
      bg="white"
      direction="column"
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        background="primary.500"
        p={4}
        gap={4}
      >
        <Image
          src={branding.logos.whiteSvg}
          alt={getLogoAlt(branding)}
          w="150px"
        />

        {matchResults.length > pageIndex && !isLoadingResults && (
          <Flex direction="column" align="center" justify="center">
            <Text fontSize="sm" color="primary.100">{`Page ${
              pageIndex + 1
            } of ${matchResults.length}`}</Text>
            <Text fontSize="4xl" fontWeight="semibold" color="white">
              {matchResults[pageIndex]?.schoolName}
            </Text>

            <Flex gap={4}>
              <Text fontSize="2xl" color="white">
                Grade {matchResults[pageIndex]?.grade}
              </Text>
              <UnorderedList>
                <ListItem fontSize="2xl" color="white">
                  {matchResults[pageIndex]?.placement}
                </ListItem>
              </UnorderedList>
            </Flex>
          </Flex>
        )}
      </Flex>

      {/* Student List */}
      {isLoadingResults ? (
        <Flex h="100%" justifyContent="center" p={8}>
          <Loading />
        </Flex>
      ) : (
        <Flex h="100%" overflowY="auto" justifyContent="center" p={8}>
          {/* Show Confetti for offers page */}
          {showConfetti && <Confetti />}
          {matchResults.length > pageIndex ? (
            <Flex direction="column" gap={8}>
              {matchResults[pageIndex]?.students.map((student) => (
                <Flex key={student.formId} direction="column" gap={2}>
                  <Flex align="center" gap={4}>
                    <Flex gap={2} align="center">
                      {student.waitlistPosition && (
                        <Text fontSize="3xl" fontWeight="semibold" w="30px">
                          {student.waitlistPosition}.
                        </Text>
                      )}
                      <Avatar
                        size="lg"
                        name={student.name}
                        className={WEGLOT_SKIP_CLASS}
                        bg={student.avatar ?? "gray.500"}
                        color="black"
                      />
                    </Flex>

                    <Text fontSize="3xl" fontWeight="semibold">
                      {student.formId.slice(0, 4)} {student.formId.slice(4, 8)}
                    </Text>
                  </Flex>
                  <Text fontSize="sm" color="gray.600">
                    {student.formId}
                  </Text>
                </Flex>
              ))}
            </Flex>
          ) : matchResults.length > 0 ? (
            <Flex
              h="100%"
              alignItems="center"
              justifyContent="center"
              direction="column"
              gap={4}
            >
              <Text fontSize="3xl" fontWeight="semibold">
                🎉 All Results Shown! 🎉
              </Text>

              <Text fontSize="sm" color="gray.600">
                Restart by clicking the button below or exit when completed.
              </Text>
            </Flex>
          ) : (
            <Flex h="100%" justifyContent="center" p={8}>
              <Loading />
            </Flex>
          )}
        </Flex>
      )}

      {/* Footer bar */}
      <Flex
        w="100%"
        p={4}
        position="sticky"
        boxShadow="dark-lg"
        justifyContent="space-between"
      >
        <Flex w="20%">
          <ExitPublicViewButton id={matchId ?? ""} />
        </Flex>

        <Flex gap={2}>
          {pageIndex > 0 && (
            <Button onClick={() => setPageIndex(pageIndex - 1)}>Back</Button>
          )}
          {matchResults.length > pageIndex ? (
            <Button onClick={() => setPageIndex(pageIndex + 1)}>Next</Button>
          ) : (
            <Button onClick={() => setPageIndex(0)}>Start over</Button>
          )}
        </Flex>

        <Flex w="20%" justifyContent="flex-end">
          {matchResults.length > pageIndex && (
            <JumpToResultsButton
              id={matchId ?? ""}
              results={matchResults
                .map((result, index) => ({
                  ...result,
                  index,
                }))
                .filter(
                  (result) =>
                    result.schoolId !== matchResults[pageIndex]?.schoolId ||
                    result.grade !== matchResults[pageIndex]?.grade ||
                    result.placement !== matchResults[pageIndex]?.placement
                )}
              onClick={setPageIndex}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
