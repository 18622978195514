import { Box, Heading, Text, Spinner, Flex } from "@chakra-ui/react";
import { ReactComponent as ClipboardHappy } from "src/images/clipboard-happy.svg";
import { useBranding } from "src/components/Providers/BrandingProvider";
import { useParams, useNavigate } from "react-router-dom";
import { useMatchService } from "src/hooks/useMatchService";
import { useEffect, useState } from "react";
import { useOrganization } from "src/hooks/useOrganization";
import * as GQL from "src/types/graphql";
import * as Url from "src/services/url";
import { Organization } from "src/types/graphql";
import * as RD from "src/types/remoteData";

export const MatchProgress = () => {
  const branding = useBranding();
  const { matchId } = useParams<{ matchId: string }>();
  const navigate = useNavigate();
  const organizationRD = useOrganization();
  const { matchRunResults, startPolling } = useMatchService();
  const [organization, setOrganization] = useState<Organization>();
  const [matchName, setMatchName] = useState<string>("");

  useEffect(() => {
    if (organizationRD.kind === RD.RemoteDataKind.Success)
      setOrganization(organizationRD.data);
  }, [organizationRD]);

  useEffect(() => {
    if (matchId) {
      startPolling(matchId);
    }
  }, [matchId, startPolling]);

  // Set match name only when first loaded
  useEffect(() => {
    if (!matchRunResults.isLoading() && matchRunResults.hasData()) {
      const name = matchRunResults.data.match_run_by_pk?.name;
      if (name) {
        setMatchName(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchRunResults.hasData() && matchRunResults.data.match_run_by_pk?.name]);

  useEffect(() => {
    if (
      !matchRunResults.isLoading() &&
      matchRunResults.hasData() &&
      organization
    ) {
      const status = matchRunResults.data.match_run_by_pk?.status;
      if (
        status === GQL.match_run_status_enum.Completed &&
        organization &&
        matchId
      ) {
        navigate(Url.OrgAdmin.Match.details(organization, matchId));
      }
    }
  }, [matchRunResults, navigate, organization, matchId]);

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={3}>
      <Heading as="h1" size="lg" textAlign="left">
        {matchName}
      </Heading>
      <Flex
        alignItems="center"
        flexDirection="column"
        gap={8}
        justifyContent="center"
        width="100%"
        minHeight="70vh"
      >
        <ClipboardHappy />
        <Flex alignItems="center" gap={2}>
          <Spinner
            color={branding?.colors.primary[500] ?? "primary.500"}
            size="sm"
          />
          <Heading as="h6">Running match</Heading>
        </Flex>
        <Text
          variant="body2"
          color="text.secondary"
          textAlign="center"
          maxWidth="300px"
        >
          Hang tight! We are running the match with the selected applicants
        </Text>
      </Flex>
    </Box>
  );
};
