import { gql } from "@apollo/client";
import axios, { HttpStatusCode } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Env from "src/services/env";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import useAccessToken from "./useAccessToken";
import { useRemoteDataQuery } from "./useRemoteDataQuery";
import useUser from "./useUser";
import { useOrganization } from "src/hooks/useOrganization";

const POLL_INTERVAL_MS = 5_000;

const CHECK_MATCH_RUN_STATUS = gql`
  query CheckMatchRunStatus($match_id: uuid!) {
    match_run_by_pk(id: $match_id) {
      status
      results_document_id
      name
    }
  }
`;

export interface FormSchoolId {
  formId: string;
  schoolId: string;
}

export interface StartMatchInput {
  matchName?: string;
  formSchools: FormSchoolId[];
  formsWithoutSchools: uuid[];
  tagLabel: string;
  enrollmentPeriodId: uuid;
  formTemplateId?: uuid;
}

const StartMatchResponseSchema = z.object({
  matchId: z.string(),
});

export function useMatchService() {
  const matchUrl = Env.read().REACT_APP_MATCH_API_URL;
  const accessToken = useAccessToken();
  const user = useUser();
  const [matchId, setMatchId] = useState("");
  const organization = useOrganization();

  const organizationId = useMemo(
    () => organization.map((o) => o.id).withDefault(""),
    [organization]
  );

  const { remoteData, startPolling, stopPolling } = useRemoteDataQuery<
    GQL.CheckMatchRunStatus,
    GQL.CheckMatchRunStatusVariables
  >(CHECK_MATCH_RUN_STATUS, {
    skip: !matchId,
    variables: {
      match_id: matchId,
    },
  });

  const startMatch = useCallback(
    ({
      matchName,
      formSchools,
      formsWithoutSchools,
      tagLabel,
      enrollmentPeriodId,
      formTemplateId,
    }: StartMatchInput) => {
      if (
        !matchUrl ||
        accessToken.status !== Status.OK ||
        user.status !== Status.OK
      ) {
        console.error("Match service unavailable:", {
          matchUrl,
          accessTokenStatus: accessToken.status,
          userStatus: user.status,
        });
        throw new Error("Unable to access Match service.");
      }

      return axios
        .post(
          `${matchUrl}/start-match`,
          {
            matchName,
            formSchools,
            formsWithoutSchools,
            tagLabel,
            enrollmentPeriodId,
            organizationId,
            formTemplateId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken.data}`,
              "Content-Type": "application/json",
              "x-hasura-role": user.data.role,
            },
          }
        )
        .then((response) => {
          if (response.status !== HttpStatusCode.Accepted) {
            throw new Error(`Internal Match error: ${response.data.error}`);
          }
          const result = StartMatchResponseSchema.parse(response.data);
          setMatchId(result.matchId);
          startPolling(POLL_INTERVAL_MS);
          return result;
        });
    },
    [matchUrl, accessToken, user, startPolling, organizationId]
  );

  useEffect(() => {
    if (remoteData.isLoading() || !remoteData.hasData()) return;
    const status = remoteData.data.match_run_by_pk?.status;
    if (status && isTerminalState(status)) stopPolling();
  }, [remoteData, stopPolling]);

  return {
    startMatch,
    matchRunResults: remoteData,
    startPolling: (matchId: string) => {
      setMatchId(matchId);
      startPolling(POLL_INTERVAL_MS);
    },
    cancelMatch: stopPolling,
  };
}

export function isTerminalState(status: GQL.match_run_status_enum) {
  switch (status) {
    case GQL.match_run_status_enum.Completed:
    case GQL.match_run_status_enum.Canceled:
    case GQL.match_run_status_enum.Error:
      return true;
  }
  return false;
}
